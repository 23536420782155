import {
    GetDeleteCheck,
    MultipleDeleteClientInvitationType
} from "../api";
import {
    GetPageAfterRowChange,
    FormatMultipleItemIds,
    GetTableDeleteMessage,
} from "@/utils/table";
import { ShowApiError } from "@/request/error";
export default {
    data() {
        return {
            multipleDeletedeleteLoading: false,
            multipleDeletedeleteLoadingText: "批量删除",
        }
    },
    methods: {
        deleteRow(row) {
            if (row.canDelete) {
                this.handleDelteRow(row).then(() => {
                    this.removeItemFormTableData([row.id]);
                });
                return false;
            }
            row.canDeleteChecking = true;
            GetDeleteCheck(row.id)
                .then((res) => {
                    row.canDelete = res.data;
                    row.canDeleteChecking = false;
                    if (res.data) {
                        this.handleDelteRow(row).then(() => {
                            this.removeItemFormTableData([row.id]);
                        });
                    } else {
                        this.$message.warning("没有权限执行此操作!");
                    }
                })
                .catch(() => {
                    row.canDelete = false;
                    row.canDeleteChecking = false;
                    this.$message.warning("权限校验异常，请稍后再试");
                });
        },
        handleDelteRow(row) {
            return new Promise((resolve, reject) => {
                this.$msgbox({
                    title: "删除提示",
                    message: `是否确定要删除【${row.name}】`,
                    showCancelButton: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    beforeClose: (action, instance, done) => {
                        if (action === "confirm") {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = "执行中...";
                            MultipleDeleteClientInvitationType([row.id])
                                .then(() => {
                                    this.$message.success("操作成功!");
                                    instance.confirmButtonLoading = false;
                                    instance.confirmButtonText = "";
                                    done();
                                    resolve();

                                })
                                .catch((err) => {
                                    done();
                                    instance.confirmButtonLoading = false;
                                    instance.confirmButtonText = "";
                                    ShowApiError("操作请求错误", err);
                                    reject();
                                });
                        } else {
                            instance.confirmButtonLoading = false;
                            instance.confirmButtonText = "";
                            done();
                            reject();
                        }
                    },
                });
            })

        },
        removeItemFormTableData(ids = []) {
            if (ids.length <= 0) {
                return false;
            }
            this.tableData = this.tableData.filter((item) => {
                return !ids.includes(item.id);
            });
            this.total = this.total - ids.length;
            this.page = GetPageAfterRowChange(
                this.page,
                this.size,
                this.tableData,
                this.total
            );
            this.onRefresh();
        },
        onCheckItemDelete() {
            return new Promise((resolve) => {
                this.multipleSelected.forEach((item) => {
                    item.canDeleteChecking = true;
                    GetDeleteCheck(item.id)
                        .then((res) => {
                            if (res.data) {
                                item.canDelete = res.data;
                            }
                        })
                        .finally(() => {
                            item.canDeleteChecking = false;
                        });
                });
                let timer = null;
                timer = setInterval(() => {
                    let flag = this.multipleSelected.every(
                        (item) => item.canDeleteChecking === false
                    );
                    if (flag) {
                        clearInterval(timer);
                        resolve();
                    }
                }, 1000);
            });
        },
        onMultipleDelete() {
            if (this.multipleSelected.length <= 0) {
                return false;
            }
            this.multipleDeletedeleteLoading = true;
            this.multipleDeletedeleteLoadingText = "正在校验操作许可...";
            this.onCheckItemDelete().then(() => {
                let { ids, nameString, unableControl } = FormatMultipleItemIds({
                    multipleSelectes: this.multipleSelected,
                    nameKey: "name",
                    conditionsKey: "canDelete",
                });
                let message = GetTableDeleteMessage({
                    total: this.multipleSelected.length,
                    ableControlCount: ids.length,
                    unableControlCount: unableControl.length,
                    nameString: nameString,
                });
                this.multipleDeletedeleteLoadingText = "检验完成，请确认...";
                this.$msgbox({
                    title: "删除提示",
                    message: message,
                    showCancelButton: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    beforeClose: (action, instance, done) => {
                        if (action === "confirm") {
                            if (ids.length <= 0) {
                                this.$message.info("无有效数据可操作");
                                done();
                                instance.confirmButtonLoading = false;
                                instance.confirmButtonText = "";
                                this.multipleDeletedeleteLoading = false;
                                this.multipleDeletedeleteLoadingText = "批量删除";
                                return;
                            }
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = "执行中...";
                            MultipleDeleteClientInvitationType(ids)
                                .then(() => {
                                    this.$message.success("操作成功");
                                    this.removeItemFormTableData(ids);
                                })
                                .catch((err) => {
                                    ShowApiError("操作请求错误", err);
                                })
                                .finally(() => {
                                    done();
                                    instance.confirmButtonLoading = false;
                                    instance.confirmButtonText = "";
                                    this.multipleDeletedeleteLoading = false;
                                    this.multipleDeletedeleteLoadingText = "批量删除";
                                });
                        } else {
                            instance.confirmButtonLoading = false;
                            instance.confirmButtonText = "";
                            this.multipleDeletedeleteLoading = false;
                            this.multipleDeletedeleteLoadingText = "批量删除";
                            done();
                        }
                    },
                });
            });
        },
    }
}